<template>
  <div class="carousel-container">
  <v-carousel
    hide-delimiters
    :continuous="true"
    :cycle="true"
    :show-arrows="false"
    height="auto"
    width="100%"
  >
    <div>
      <v-carousel-item v-for="(banner, index) in banners" :key="index">
        <img
          :src="banner"
          alt="Home Screen Banner"
          @click="redirectTo(banner)"
          style="border-radius: 0.5rem; width: 100%;"
        />
      </v-carousel-item>
    </div>
  </v-carousel>
</div>
</template>


<script>
export default {
  name: "bannersComponent",
  components: {
  },
  props: {
    banners: {
      type: Array,
      requied: true,
    },
  },
  data() {
    return {
      screenWidth: window.innerWidth,
      bannerIndex: 0,
      imagelink: this.banners
    };
  },
  watch: {
    screenWidth(newWidth, oldWidth) {},
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    redirectTo(banner) {},
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style>
.carousel-container {
  display: flex;
  justify-content: center;
}
</style>
